module.exports = [
{"section":"2.1.1", "questions":["E2C05","E2C06","E2C08","E2C10","E2C11","E2C12"]},
{"section":"2.1.2", "questions":["E2C01","E2C02","E2C03","E2C04","E2C07","E2C13"]},

{"section":"2.2.1", "questions":["E2D04","E2D05"]},
{"section":"2.2.2", "questions":["E2A14","E2D07","E2D08","E2D10","E2D11"]},

{"section":"2.3.1", "questions":["E2A01","E2A02","E2A03","E2A10","E2A11","E2A12","E2A13"]},
{"section":"2.3.2", "questions":["E2A04","E2A05","E2A06","E2A07","E2A08","E2A09"]},



{"section":"3.1.0", "questions":["E1A01","E1A02","E1A03","E1A04","E1A12"]},
{"section":"3.1.1", "questions":["E1A05","E1A06","E1A07","E1A14"]},
{"section":"3.1.2", "questions":["E1A08", "E1A09"]},
{"section":"3.1.3", "questions":["E1B09", "E1B10"]},
{"section":"3.1.4", "questions":["E1A10","E1A11","E1A13"]},

{"section":"3.2.1", "questions":["E1B01","E1B08","E1B11"]},
{"section":"3.2.2", "questions":["E1B02","E1B03","E1B04","E1B06"]},

{"section":"3.3.1", "questions":["E1C01","E1C06","E1C07","E1C08"]},
{"section":"3.3.2", "questions":["E1C02","E1C03","E1C05","E1C09","E1C10"]},

{"section":"3.4.1", "questions":["E1D01","E1D02","E1D03","E1D04"]},
{"section":"3.4.2", "questions":["E1D05","E1D06","E1D07","E1D08","E1D09","E1D10","E1D11"]},

{"section":"3.5.1", "questions":["E1E03","E1E04"]},
{"section":"3.5.2", "questions":["E1E01","E1E02","E1E05","E1E06","E1E07","E1E08","E1E09","E1E10","E1E11","E1E12","E1E13","E1E14"]},

{"section":"3.6.1", "questions":["E1F12"]},
{"section":"3.6.2", "questions":["E1F03","E1F11"]},
{"section":"3.6.3", "questions":["E1B05","E1F04","E1F05"]},
{"section":"3.6.4", "questions":["E1C12","E1F07","E1F08"]},
{"section":"3.6.5", "questions":["E1F01","E1F09","E1F10"]},
{"section":"3.6.6", "questions":["E1C04","E1C11","E1C13","E1F02"]},
{"section":"3.6.7", "questions":["E1F06"]},



{"section":"4.1", "questions":["E5C11"]},

{"section":"4.2", "questions":["E3A15","E3A16","E3A17","E5D08"]},

{"section":"4.3.1", "questions":["E5B01","E5B02","E5B04","E5D06","E5D07"]},
{"section":"4.3.2", "questions":["E5B09","E5B10"]},
{"section":"4.3.3", "questions":["E5C01","E5C02","E5C03","E5C04","E5C05","E5C06","E5C07","E5C08","E5C09","E5C10","E5C12","E5C13"]},
{"section":"4.3.4", "questions":["E5B03","E5B05","E5B06","E5B07","E5B08","E5B11","E5B12","E5B13","E5C14","E5C15","E5C16","E5C17"]},
{"section":"4.3.5", "questions":["E5D09","E5D10","E5D11","E5D12","E5D13","E5D14","E5D15","E5D16","E5D17","E5D18"]},
{"section":"4.3.6", "questions":["E5A01","E5A02","E5A03","E5A04","E5A05","E5A06","E5A07","E5A08","E5A14","E5A16"]},
{"section":"4.3.7", "questions":["E4B15","E5A09","E5A10","E5A11","E5A12","E5A13","E5A15","E5A17"]},
{"section":"4.3.8", "questions":["E5D01","E5D02","E5D04","E5D05","E6D13","E6E02","E6E09","E6E10","E6E11","E6E12"]},
{"section":"4.3.9", "questions":["E6D01","E6D04","E6D05","E6D06","E6D07","E6D08","E6D09","E6D10","E6D11","E6D12","E6D14","E6D15","E6D17"]},

{"section":"5.1.1", "questions":[
  "E6A02","E6A04","E6A15","E6A16" 
]},
{"section":"5.1.2", "questions":[
  "E6A03", "E6B01","E6B02","E6B03","E6B04","E6B05","E6B06","E6B07","E6B08","E6B09",
  "E6B11","E6B12"
]},
{"section":"5.1.3", "questions":[
  "E6A05","E6A06","E6A07","E6A08"
]},
{"section":"5.1.4", "questions":[
  "E6A09","E6A10","E6A11","E6A12","E6A14","E6A17"
]},
{"section":"5.1.5", "questions":[
  "E5D03","E6A01","E6E03","E6E04","E6E06","E6E07","E6E08"
]},
{"section":"5.2", "questions":[
  "E6B10","E6B13","E6E01","E6F13","E6F14"
]},
  
{"section":"5.3.1", "questions":[
  "E6C03","E6C04","E6C08","E6C10","E6C11","E7A07","E7A08","E7A09","E7A10","E7A11","E7A12"
]},
{"section":"5.3.2", "questions":[
  "E7A01","E7A03","E7A04","E7A05","E7A06"
]},
{"section":"5.3.3", "questions":[
  "E6A13","E6C05","E6C06","E6C07","E6C09","E6C12","E6C13","E6C14","E7A02"
]},
{"section":"5.4", "questions":[
  "E6F01","E6F02","E6F03","E6F04","E6F05","E6F06","E6F07","E6F08","E6F09","E6F10",
  "E6F11","E6F12"
]},

{"section":"6.1.1", "questions":[
  "E7B10","E7B11","E7B12","E7B13","E7B15","E7B18"
]},
{"section":"6.1.2", "questions":[
  "E6C01","E6C02","E7G01","E7G03","E7G04","E7G07","E7G08","E7G09","E7G10","E7G11","E7G12"
]},
{"section":"6.1.3", "questions":[
  "E7B01","E7B02","E7B03","E7B04","E7B06","E7B07","E7B14","E7B16","E7B17"
]},
{"section":"6.1.4", "questions":[
  "E7B05","E7B08"
]},
{"section":"6.2", "questions":[
  "E6D02","E6D03","E7E01","E7E02","E7E03","E7E04","E7E05","E7E06","E7E07","E7E08",
  "E7E09","E7E10","E7E11","E7E12","E7H01","E7H02","E7H03","E7H04","E7H05","E7H06",
  "E7H07","E7H08","E7H09","E7H10","E7H11","E7H12","E7H13","E7H14","E7H15"
]},
{"section":"6.3", "questions":[
  "E7F01","E7F03","E7F04","E7F05","E7F06","E7F07","E7F08","E7F09","E7F10","E7F11",
  "E7F12","E7F16","E7F17","E8A01","E8A04","E8A08","E8A09","E8A10","E8A11","E8A12",
  "E8A13"
]},
{"section":"6.4", "questions":[
  "E7B09","E7C01","E7C02","E7C03","E7C04","E7C05","E7C06","E7C07","E7C08","E7C09",
  "E7C10","E7C11","E7C12","E7C13","E7C14","E7C15","E7F02","E7F13","E7F14","E7F15",
  "E7G02","E7G05","E7G06"
]},
{"section":"6.5", "questions":[
  "E7D01","E7D02","E7D03","E7D04","E7D05","E7D06","E7D07","E7D08","E7D09","E7D10",
  "E7D11","E7D12","E7D13","E7D14","E7D15","E7D16"
]},

{"section":"7.1", "questions":[
  "E8A02","E8A03","E8A05","E8A06","E8A07"
]},
{"section":"7.2", "questions":[
  "E4A01","E4A02","E4A03","E4A04","E4A05","E4A06","E4A09","E4A10","E4A11","E4A12",
  "E4A13","E4A14","E4A15","E4B01","E4B02","E4B03","E4B04","E4B05","E4B08","E4B10",
  "E4B12","E4B14"
]},
{"section":"7.3", "questions":[
  "E4C01","E4C02","E4C03","E4C04","E4C05","E4C06","E4C07","E4C08","E4C09","E4C10",
  "E4C11","E4C12","E4C13","E4C14","E4C15","E4C16","E4C17","E4D01","E4D02","E4D05",
  "E4D09","E4D10","E4D11","E4D12","E4D13","E4D14","E6E05"
]},
{"section":"7.4", "questions":[
  "E4D03","E4D04","E4D06","E4D07","E4D08","E4E01","E4E02","E4E03","E4E04","E4E05",
  "E4E06","E4E07","E4E08","E4E09","E4E10","E4E11","E4E12","E4E13","E4E13","E4E15",
  "E4E16","E6D16"
]},

{"section":"8.1", "questions":[
  "E1B07","E8B01","E8B02","E8B03","E8B04","E8B05","E8B06","E8B09","E8B10","E8B11"
]},
{"section":"8.2", "questions":[
  "E2C09","E2D01","E2D03","E2D09","E2D12","E2D13","E2D14","E2E01","E2E02","E2E03",
  "E2E04","E2E05","E2E06","E2E07","E2E08","E2E09","E2E10","E2E11","E2E12","E2E13",
  "E8B07","E8B08","E8C01","E8C02","E8C03","E8C04","E8C05","E8C06","E8C07","E8C08",
  "E8C09","E8C10","E8C11","E8D01","E8D02","E8D03","E8D04","E8D05","E8D06","E8D07",
  "E8D08","E8D09","E8D10","E8D11","E8D12"
]},
{"section":"8.3", "questions":[
  "E2B01","E2B02","E2B03","E2B04","E2B05","E2B06","E2B07","E2B08","E2B09","E2B10",
  "E2B11","E2B12","E2B13","E2B14","E2B15","E2B16","E2B17","E2B18","E2B19"
]},

{"section":"9.1", "questions":[
  "E9A01","E9A02","E9A04","E9A05","E9A06","E9A07","E9A08","E9A09","E9A10","E9A11",
  "E9A12","E9A13","E9A14","E9B01","E9B02","E9B03","E9B05","E9B06","E9B07","E9B08",
  "E9B12","E9B15","E9B16","E9C11","E9C13","E9C14","E9C15","E9D08","E9D11","E9D12"
]},
{"section":"9.2", "questions":[
  "E9C01","E9C02","E9C03","E9C04","E9C05","E9C06","E9C07","E9C08","E9C09","E9C10",
  "E9C12","E9D01","E9D02","E9D03","E9D04","E9D05","E9D06","E9D07","E9D09","E9D10",
  "E9E12","E9E13","E9H01","E9H02","E9H04","E9H05","E9H06","E9H07","E9H08","E9H09",
  "E9H10","E9H11"
]},
{"section":"9.3", "questions":[
  "E9A03","E9A15","E9A16","E9A17","E9A18","E9E01","E9E02","E9E03","E9E04","E9E05",
  "E9E06","E9E09","E9E11"
]},
{"section":"9.4", "questions":[
  "E4A07","E4A08","E4B06","E4B07","E4B09","E4B11","E4B13","E4B16","E4B17","E9E07",
  "E9E08","E9E10","E9F01","E9F02","E9F03","E9F04","E9F05","E9F06","E9F07","E9F08",
  "E9F09","E9F10","E9F11","E9F12","E9F13","E9F14","E9F15","E9F16","E9G01","E9G02",
  "E9G03","E9G04","E9G05","E9G06","E9G07","E9G08","E9G09","E9G10","E9G11"
]},
{"section":"9.5", "questions":[
  "E9B04","E9B09","E9B10","E9B11","E9B13","E9B14","E9D13"
]},

{"section":"10.1", "questions":[
  "E3C02","E3C04","E3C05","E3C07","E3C08","E3C09","E3C10"
]},
{"section":"10.2", "questions":[
  "E3B04","E3B05","E3B06","E3B07","E3B08","E3B10","E3B12","E3B13","E3B14","E3C01",
  "E3C03","E3C11","E3C12","E3C13","E3C15"
]},
{"section":"10.3", "questions":[
  "E2D02","E2D06","E3A01","E3A02","E3A03","E3A04","E3A05","E3A06","E3A07","E3A08",
  "E3A09","E3A10","E3A11","E3A12","E3A13","E3A14","E3B01","E3B02","E3B03","E3B09",
  "E3B11","E3C06","E3C14"
]},

{"section":"11.1", "questions":[
  "E0A07","E0A09","E0A10"
]},
{"section":"11.2", "questions":[
  "E0A02","E0A03","E0A04","E0A05","E0A06","E0A08","E0A11"
]},
{"section":"11.3", "questions":[
  "E0A01"
]}
]


;