module.exports = [
{"section":"2.1", "questions":["T3B01","T3B04","T3B05","T3B06","T3B07","T3B08","T3B09","T3B10","T3B11","T5A12"]},
{"section":"2.2", "questions":["T1B09","T2B05","T2B06","T2B07","T8A01","T8A02","T8A03","T8A04","T8A05","T8A06","T8A07"]},
{"section":"2.3", "questions":["T7A02"]},

{"section":"3.1", "questions":[
  "T5A01","T5A02","T5A03","T5A04","T5A05","T5A07","T5A08","T5A09","T5A10","T5A11",
  "T5C08","T5C09","T5C10","T5C11","T5D01","T5D02","T5D03","T5D04","T5D05","T5D06",
  "T5D07","T5D08","T5D09","T5D10","T5D11","T5D12","T7D01","T7D02","T7D03","T7D04",
  "T7D05","T7D06","T7D07","T7D10","T7D11","T7D12"]},
{"section":"3.2", "questions":[
  "T5C01","T5C02","T5C03","T5C04","T5C12","T5C13","T6A01","T6A02","T6A03","T6A04",
  "T6A05","T6A06","T6A07","T6A08","T6A09","T6A10","T6B01","T6B02","T6B03","T6B04",
  "T6B05","T6B06","T6B07","T6B08","T6B09","T6B10","T6B11","T6B12","T6C01","T6C02",
  "T6C03","T6C04","T6C05","T6C06","T6C07","T6C08","T6C09","T6C10","T6C11","T6C12",
  "T6C13","T6D01","T6D02","T6D03","T6D04","T6D05","T6D06","T6D07","T6D08","T6D09",
  "T6D10","T6D11","T0A04","T0A05"]},
{"section":"3.3", "questions":["T6A11","T7A01","T7A03","T7A04","T7A05","T7A06","T7A08","T7A11"]},

{"section":"4.1", "questions":[
  "T3A01","T3A02","T3A06","T3A08","T3A10","T3A11","T3C01","T3C02","T3C03","T3C04",
  "T3C05","T3C06","T3C07","T3C08","T3C09","T3C10","T3C11","T3C12"]},
{"section":"4.2", "questions":["T3A04","T3A07","T3A09","T3B02","T3B03","T5B09","T5B10","T5B11","T5C07","T9A02","T9A11"]},
{"section":"4.3", "questions":["T7C03","T7C04","T7C05","T7C06","T7C07","T7C12","T9B01","T9B02","T9B03","T9B05","T9B09","T9B11"]},
{"section":"4.4", "questions":[
  "T3A03","T3A05","T4A05","T7C02","T7C08","T7C09","T7C10","T7C11","T7D08","T7D09",
  "T9A01","T9A03","T9A04","T9A05","T9A06","T9A07","T9A08","T9A09","T9A10","T9A12",
  "T9A13","T9A14","T9B04","T9B06","T9B07","T9B08","T9B10"]},

{"section":"5.1", "questions":[
  "T2B03","T4A01","T4A02","T4B01","T4B02","T4B03","T4B04","T4B05","T4B06","T4B07",
  "T4B08","T4B09","T4B10","T4B12","T7A07","T7A10","T7B01","T7C01","T7C13","T8D10"]},
{"section":"5.2", "questions":[
  "T4A06","T4A07","T8C11","T8D01","T8D02","T8D03","T8D05","T8D06","T8D07","T8D08",
  "T8D09","T8D11"]},
{"section":"5.3", "questions":["T4A03","T4A10","T4A11","T4A12","T5A06","T0A09","T0A10"]},
{"section":"5.4", "questions":[
  "T4A04","T4A09","T6D12","T7B02","T7B03","T7B04","T7B05","T7B06","T7B07","T7B08",
  "T7B09","T7B12"]},
{"section":"5.5", "questions":["T4A08","T7B11"]},

{"section":"6.1", "questions":["T2B10","T2B11","T8C05"]},
{"section":"6.2", "questions":["T2B01","T2B13"]},
{"section":"6.3", "questions":["T2A02","T2A04","T2A05","T2A08","T2A09","T2B12","T7B10"]},
{"section":"6.4", "questions":[
  "T2A01","T2A03","T2B02","T2B04","T2C02","T4B11","T8C06","T8C09","T8C10","T8C12",
  "T8C13"]},
{"section":"6.5", "questions":["T2C03","T2C06","T2C07","T2C08","T2C10","T2C11"]},
{"section":"6.6", "questions":["T2C01","T2C04","T2C05","T2C09","T2C12"]},
{"section":"6.7", "questions":[
  "T1A07","T1A13","T7A09","T8B01","T8B02","T8B03","T8B04","T8B05","T8B06","T8B07",
  "T8B08","T8B09","T8B10","T8B11","T8C01","T8C02","T8C03","T8C04","T8C07","T8C08",
  "T8D04"]},

{"section":"7.1", "questions":[
  "T1A01","T1A02","T1A03","T1A05","T1A10","T1A12","T1C07","T1C08","T1C09","T1C10",
  "T1C11","T1C13","T1F13"]},
{"section":"7.2", "questions":[]},
{"section":"7.3", "questions":[
  "T1A08","T1A09","T1A14","T1B03","T1B04","T1B05","T1B06","T1B07","T1B08","T1B10",
  "T1B11","T1B13","T1C01","T2A10","T2A11","T2A12"
]},
{"section":"7.4", "questions":["T1B01","T1B02","T1B12","T1C02","T1C03","T1C04","T1C06","T1D01"]},
{"section":"7.5", "questions":["T1C05","T1C12","T1C14","T1F12"]},

{"section":"8.1", "questions":["T1E01","T1E02","T1E03","T1E04","T1E05","T1E07","T1E11","T1E12"]},
{"section":"8.2", "questions":[
  "T1D11","T1F01","T1F02","T1F03","T1F04","T1F05","T1F06","T1F08","T2A06","T2A07",
  "T2B09"]},
{"section":"8.3", "questions":["T1A04","T1A06","T1A11","T2B08"]},
{"section":"8.4", "questions":["T1F07","T1F11"]},
{"section":"8.5", "questions":["T1E06","T1E08","T1E09","T1E10","T1F10"]},
{"section":"8.6", "questions":[
  "T1D02","T1D03","T1D04","T1D05","T1D06","T1D07","T1D08","T1D09","T1D10","T1D12",
  "T1F09"]},

{"section":"9.1", "questions":["T0A01","T0A02","T0A03","T0A06","T0A07","T0A08","T0A11","T0B10","T0B11","T0B12"]},
{"section":"9.2", "questions":[
  "T0C01","T0C02","T0C03","T0C04","T0C05","T0C06","T0C07","T0C08","T0C09","T0C10",
  "T0C11","T0C12","T0C13"]},
{"section":"9.3", "questions":["T0B01","T0B02","T0B03","T0B04","T0B05","T0B06","T0B07","T0B08","T0B09"]}
]
;