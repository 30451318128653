module.exports = [
{"section":"2.1", "questions":[
    "G1B08","G2A01","G2A02","G2A03","G2A04","G2A05","G2A06","G2A07","G2A08","G2A09",
    "G2A10","G2A11","G2B01","G2B03","G2B04","G2B05","G2B06","G2B07","G2B08","G2C01",
    "G2C02","G2C03","G2C04","G2C05","G2C06","G2C07","G2C08","G2C10","G2C09","G2C11",
    "G2D08","G2D09","G4A10"]},
{"section":"2.2", "questions":["G1B04","G2B02","G2B09","G2B10","G2B11","G2B12"]},


{"section":"3.1", "questions":["G1A14","G1B01","G2D01","G2D02","G2D03"]},{"section":"3.2", "questions":[
  "G1D01","G1D02","G1D03","G1D04","G1D05","G1D06","G1D07","G1D08","G1D09","G1D10",
  "G1D11","G1E09"]},
{"section":"3.3", "questions":[
  "G1A01","G1A02","G1A03","G1A04","G1A05","G1A06","G1A07","G1A08","G1A09","G1A10",
  "G1A11","G1A12","G1A13","G1B02","G1B03","G1B05","G1B06","G1B07","G1B09","G1B10",
  "G1E01","G1E02","G1E04","G1E05","G1E06","G1E07","G1E08","G2D05","G2D07"]},
{"section":"3.4", "questions":[
  "G1B11","G1B12","G1C01","G1C02","G1C03","G1C04","G1C05","G1C06","G1C07","G1C08",
  "G1C09","G1C10","G1C11","G2D10"]},


{"section":"4.1", "questions":["G5B01","G5B03","G5B04","G5B05","G5B10"]},
{"section":"4.2", "questions":["G5B06","G5B07","G5B09","G5B11","G5B12","G5B13","G5B14"]},
{"section":"4.3", "questions":[
  "G5B02","G5C01","G5C02","G5C03","G5C04","G5C05","G5C06","G5C08","G5C09","G5C10",
  "G5C11","G5C12","G5C13","G5C14","G5C15","G5C16","G5C17","G5C18","G6A13","G6A14",
  "G6A15","G6A16","G6A17","G6A18","G6A19","G7A09","G7A10","G7A11","G7A12","G7A13"]},
{"section":"4.4", "questions":[
  "G5A01","G5A02","G5A03","G5A04","G5A05","G5A06","G5A07","G5A08","G5A09","G5A10",
  "G5A11","G5A12","G5A13","G5C07"]},
{"section":"4.5", "questions":[
  "G6A03","G6A05","G6A06","G6A07","G6A08","G6A09","G6A10","G6A11","G6A12","G6B01",
  "G6B02","G6B03","G6B04","G6B05","G6B06","G6B07","G6B08","G6B09","G6B10","G6B11",
  "G7B01","G7B02","G7B03","G7B04","G7B05","G7B06"]},
{"section":"4.6", "questions":[
  "G4E08","G4E09","G4E10","G4E11","G6A01","G6A02","G6A04","G6B12","G6B13","G6B14",
  "G6B15","G6B16","G6B17","G6B18","G7A01","G7A02","G7A03","G7A04","G7A05","G7A06",
  "G7A07","G7A08"]},
{"section":"4.7", "questions":[
  "G4B01","G4B02","G4B03","G4B04","G4B05","G4B06","G4B08","G4B09","G4B10","G4B11",
  "G4B12","G4B13","G4B14"]},


{"section":"5.1", "questions":["G8A02","G8A03","G8A05","G8A07"]},
{"section":"5.2", "questions":[
  "G4D08","G4D09","G4D10","G4D11","G7B07","G7B09","G7C05","G8A04","G8A06","G8B01",
  "G8B03","G8B04"]},
{"section":"5.3", "questions":[
  "G4A03","G4A04","G4A05","G4A07","G4A08","G4A09","G4A12","G4B07","G4B15","G4D01",
  "G4D02","G4D03","G7B08","G7B10","G7B11","G7B12","G7B13","G7B14","G7C01","G7C02",
  "G8A08","G8A09","G8A10","G8A11","G8B06","G8B07"]},
{"section":"5.4", "questions":[
  "G4A01","G4A02","G4A11","G4A13","G4C11","G4C12","G4C13","G4D04","G4D05","G4D06",
  "G4D07","G7C03","G7C04","G7C07","G7C08","G7C09","G7C10","G7C11","G8B02","G8B09"]},
{"section":"5.4", "questions":[
  "G4A15","G4C01","G4C02","G4C03","G4C04","G4C05","G4C06","G4C07","G4C08","G4C09",
  "G4C10","G4E03","G4E04","G4E05","G4E07","G7C06"]},


{"section":"6.1", "questions":["G2E04","G2E07","G2E08","G2E13","G8C01"]},
{"section":"6.2", "questions":["G8A01","G8C11"]},
{"section":"6.3", "questions":["G2E06","G8C02","G8C04","G8C08","G8C09","G8C12"]},
{"section":"6.4", "questions":["G2E02","G2E09","G8C03","G8C05","G8C07","G8C10"]},
{"section":"6.5", "questions":["G2E01","G2E05","G2E11","G2E14","G4A14","G8B05","G8B08","G8B10"]},
{"section":"6.6", "questions":["G1E03","G1E11","G1E12","G1E13","G2E03","G2E10","G2E12","G8C06"]},


{"section":"7.2", "questions":[
  "G4E01","G4E02","G4E06","G9B01","G9B02","G9B03","G9B04","G9B05","G9B06","G9B07",
  "G9B08","G9B09","G9B10","G9B11","G9B12","G9C19","G9C20"]},
{"section":"7.3", "questions":[
  "G2D04","G2D11","G9C01","G9C02","G9C03","G9C04","G9C05","G9C07","G9C08","G9C10",
  "G9C11","G9C12"]},
{"section":"7.4", "questions":["G9C06","G9C13","G9C14","G9C15","G9C16","G9C17","G9C18"]},
{"section":"7.5", "questions":[
  "G9C09","G9D01","G9D02","G9D03","G9D04","G9D05","G9D06","G9D07","G9D08","G9D09",
  "G9D10","G9D11"]},
{"section":"7.6", "questions":[
  "G4A06","G9A01","G9A02","G9A03","G9A04","G9A05","G9A06","G9A07","G9A08","G9A09",
  "G9A10","G9A11","G9A12","G9A13","G9A14","G9A15"]},


{"section":"8.1", "questions":[
  "G2D06","G3B01","G3B02","G3B05","G3B09","G3B10","G3C01","G3C02","G3C03","G3C04",
  "G3C05","G3C12"]},
{"section":"8.2", "questions":[
  "G3A01","G3A02","G3A03","G3A04","G3A05","G3A06","G3A07","G3A08","G3A09","G3A10",
  "G3A11","G3A12","G3A13","G3A14","G3A15","G3A16","G3B03","G3B04","G3B06","G3B07",
  "G3B08","G3B11","G3B12"]},
{"section":"8.3", "questions":["G3C06","G3C07","G3C08","G3C09","G3C10","G3C11","G3C13"]},


{"section":"9.1", "questions":[
  "G0B01","G0B02","G0B03","G0B04","G0B05","G0B06","G0B09","G0B10","G0B11","G0B12",
  "G0B13","G0B14","G0B15"]},
{"section":"9.2", "questions":[
  "G0A01","G0A02","G0A03","G0A04","G0A05","G0A06","G0A07","G0A08","G0A09","G0A10",
  "G0A11"]},
{"section":"9.3", "questions":["G0A12","G0B07","G0B08"]}
]


;